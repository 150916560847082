import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Bench Press 12-12-12\\@60% 1RM`}</p>
    <p>{`&`}</p>
    <p>{`DB Rows 12-12-12\\@60% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds of:`}</p>
    <p>{`3-Bar Muscle Ups`}</p>
    <p>{`6-HSPU’s`}</p>
    <p>{`9-GHD Situps`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds of:`}</p>
    <p>{`9-CTB Pullups`}</p>
    <p>{`12-Legless Wall Balls (20/10 to 9′ target)`}</p>
    <p>{`15-K2E’s`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`15-Pullups`}</p>
    <p>{`18-Ring Pushups`}</p>
    <p>{`21-Med Ball Situps, 20`}{`#`}{` ball`}</p>
    <p>{`For total time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Kids at Louisville East today from 9:30-10:00am.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Annual Christmas party will be Saturday, December 10th.  More
info to come soon!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      